<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          v-slot="{ invalid }"
          tag="form"
        >

          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  label="Campaign Name (En)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="campaignsForm.name_en"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Campaign Name (En)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  label="Campaign Name (Ar)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="campaignsForm.name_ar"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Campaign Name (Ar)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Video Slogan -->
              <validation-provider
                #default="validationContext"
                name="Video Slogan (En)"
                rules="required"
              >
                <b-form-group
                  label="Video Slogan (En)"
                  label-for="Video Slogan (En)"
                >
                  <b-form-input
                    id="Video Slogan (En)"
                    v-model="campaignsForm.slogan_en"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Video Slogan (En)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Video Slogan -->
              <validation-provider
                #default="validationContext"
                name="Video Slogan (Ar)"
                rules="required"
              >
                <b-form-group
                  label="Video Slogan (Ar)"
                  label-for="Video Slogan (Ar)"
                >
                  <b-form-input
                    id="Video Slogan (Ar)"
                    v-model="campaignsForm.slogan_ar"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Video Slogan (Ar)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  label="Description (En)"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="campaignsForm.description_en"
                    trim
                    type="text"
                    placeholder="Description"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  label="Description (Ar)"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="campaignsForm.description_ar"
                    trim
                    type="text"
                    placeholder="Description"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Countries"
              >
                <b-form-group
                  label="Country"
                  label-for="Countries"
                >
                  <v-select
                    v-model="campaignsForm.country_id"
                    :options="countries"
                    :filterable="false"
                    label="name"
                    :reduce="countries => countries.id"
                    :state="getValidationState(validationContext)"
                    @search="searchCountries"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="gifts"
              >
                <b-form-group
                  label="gifts"
                  label-for="gifts"
                >
                  <v-select
                    v-model="campaignsForm.gift_id"
                    :options="gifts"
                    :filterable="false"
                    label="name"
                    :reduce="gifts => gifts.id"
                    @search="searchGifts"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="First Line"
              >
                <b-form-group>
                  <label>First Line</label>
                  <a
                    class="addNew"
                    @click=" openTagModal"
                  >
                    <span> <feather-icon
                      icon="PlusIcon"
                      size="10"
                    />add new</span>
                  </a>
                  <v-select
                    v-model="campaignsForm.first_tag_id"
                    :options="firstLine"
                    :filterable="false"
                    label="tag"
                    :reduce="firstLine => firstLine.id"
                    @search="searchFirstLine"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Second Line"
              >
                <b-form-group>
                  <label>Second Line</label>
                  <a
                    class="addNew"
                    @click=" openTagModal"
                  >
                    <span> <feather-icon
                      icon="PlusIcon"
                      size="10"
                    />add new</span>
                  </a>
                  <v-select
                    v-model="campaignsForm.second_tag_id"
                    :options="firstLine"
                    :filterable="false"
                    label="tag"
                    :reduce="firstLine => firstLine.id"
                    @search="searchFirstLine"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="campaignsForm.start_type==2"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Coming Soon Date"
              >
                <b-form-group
                  label="Coming Soon Date"
                >
                  <b-form-datepicker
                    id="Coming-datepicker"
                    v-model="campaignsForm.coming_soon_date"
                    :state="getValidationState(validationContext)"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Draw Date"
              >
                <b-form-group
                  label="Draw Date"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="campaignsForm.draw_date"
                    :state="getValidationState(validationContext)"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Start type"
                rules="required"
              >
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Start type"
                >
                  <b-form-radio
                    v-model="campaignsForm.start_type"
                    :aria-describedby="ariaDescribedby"
                    name="start-type"
                    value="1"
                  >
                    Start Immediately
                  </b-form-radio>
                  <b-form-radio
                    v-model="campaignsForm.start_type"
                    :aria-describedby="ariaDescribedby"
                    name="start-type"
                    value="2"
                  >
                    Start Later
                  </b-form-radio>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Active"
                label-for="Active"
              >
                <b-form-checkbox
                  id="Active"
                  v-model="campaignsForm.is_active"
                  unchecked-value="0"
                  value="1"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Has Vote"
                label-for="Has Vote"
              >
                <b-form-checkbox
                  id="Has Vote"
                  v-model="campaignsForm.has_vote_option"
                  :disabled="$route.params.id"
                  unchecked-value="0"
                  value="1"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="campaignsForm.has_vote_option==1"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name=" Number Of Votes For Free tickets"
                rules="required|even"
              >
                <b-form-group
                  label="Number Of Votes For Free tickets (even number)"
                  label-for="number_for_free_tickets"
                >
                  <b-form-input
                    id="number_for_free_tickets"
                    v-model.number="campaignsForm.number_for_free_ticket"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="Number Of Votes For Free tickets"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Address Required"
                label-for="Address Required"
              >
                <b-form-checkbox
                  id="Address Required"
                  v-model="campaignsForm.address_required"
                  unchecked-value="0"
                  value="1"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-media
                no-body
                class="mb-1"
              >
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      v-img
                      rounded
                      :src="campaignsForm.image"
                      height="100"
                      width="100"
                    />
                  </b-link>
                <!--/ image -->
                </b-media-aside>

                <b-media-body>
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="selectImage()"
                  >
                    Upload Image
                  </b-button>
                  <b-form-file
                    ref="refInputImg"
                    accept=".jpg, .png, .jpeg"
                    :hidden="true"
                    plain
                    @change="changeProfile($event)"
                  />
                <!--/ upload button -->
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="6">
              <b-media
                no-body
                class="mb-1"
              >
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      v-img
                      rounded
                      :src="campaignsForm.logo"
                      height="100"
                      width="100"
                    />
                  </b-link>
                <!--/ image -->
                </b-media-aside>

                <b-media-body>
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="selectLogo()"
                  >
                    Upload Logo
                  </b-button>
                  <b-form-file
                    ref="refInputLogo"
                    accept=".jpg, .png"
                    :hidden="true"
                    plain
                    @change="changeLogo($event)"
                  />
                <!--/ upload button -->
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="6">
              <b-media
                no-body
                class="mb-1"
              >
                <video
                  id="video-preview"
                  controls
                  width="300"
                  class="mr-2"
                />

                <b-media-body>
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 "
                    @click="selectFile()"
                  >
                    Upload Video
                  </b-button>
                  <b-form-file
                    ref="refInputEl"
                    accept="video/*"
                    :hidden="true"
                    plain
                    @change="handleFileUpload($event)"
                  />
                  <!--/ upload button -->
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              v-if="errorMsg"
              cols="12"
            >
              <p class="text-danger">
                {{ errorMsg }}
              </p>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                :disabled="invalid || Object.values(errors).length > 0"
                @click="addCampaign()"
              >
                Save
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
      <div>
        <b-modal
          id="add-tag"
          ref="add-tag"
          hide-footer
          centered
          title="Add Tag"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col md="6">
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name="Name (En)"
                  rules="required"
                >
                  <b-form-group
                    label="Name (En)"
                    label-for="name_en"
                  >
                    <b-form-input
                      id="name_en"
                      v-model="tagsForm.name_en"

                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Name"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name="Name (Ar)"
                  rules="required"
                >
                  <b-form-group
                    label="Name (Ar)"
                    label-for="name_ar"
                  >
                    <b-form-input
                      id="name_ar"
                      v-model="tagsForm.name_ar"

                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Name"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Server Errors -->
              <b-col
                v-if="Object.values(errors).length > 0"
                cols="12"
              >
                <p
                  v-for="(error, key, index) in errors"
                  :key="index"
                  class="text-danger small"
                >
                  {{ error.toString() }}
                </p>
              </b-col>
            </b-row>

          </validation-observer>

          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              @click.prevent="addTags"
            >
              Save
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-modal>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, email } from '@validations'
import axios from 'axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import handleFormData from '@/global-compositions/handleFormData'
// import store from '@/store'

export default {
  components: { vSelect },
  data() {
    return {
      required,
      email,
      gifts: [],
      products: [],
      firstLine: [],
      tagsForm: [],
      countries: [],
      test: '',
      value: '',
      loader: false,
      validationErrors: {},
      image: '',
      logo: '',
      is_active: true,
      errorMsg: '',
      errors: {},
      campaignTypes: [],
      file: '',

    }
  },
  computed: {
    ...mapGetters({ currentCountry: 'app/getCountry' }),

  },

  setup() {
    const campaignsForm = ref({
      image: null,
      video: null,
      logo: null,
      campaign_id: 4,
      quantity_limit: 0,
      limited: 0,
      alert_before_days: 0,
      is_active: 0,
      address_required: 0,
      has_vote_option: 0,

    })
    const {
      getValidationState,
    } = formValidation()
    const { formData, setFormData } = handleFormData()
    const file = ref('')

    return {
      getValidationState,
      campaignsForm,
      formData,
      setFormData,
      file,

    }
  },
  mounted() {
    this.showCampaign()
    this.getGifts()
    this.getProducts()
    this.getFirstLine()
    this.getTypes()
    this.getCountries()
  },
  methods: {
    openTagModal() {
      this.$bvModal.show('add-tag')
    },
    closeModal() {
      this.$bvModal.hide('add-tag')
    },

    selectLogo() {
      this.$refs.refInputLogo.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        const standard = 1.62 / 0.98
        const belowStandard = 1.62 / 1.05
        const userRatio = img.width / img.height
        if (userRatio <= standard && userRatio >= belowStandard) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.campaignsForm.image = er.target.result
            }

            // eslint-disable-next-line prefer-destructuring
            this.image = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image Aspect Ratio is Not Valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
    },
    changeLogo(e) {
      // eslint-disable-next-line prefer-destructuring
      this.logo = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.campaignsForm.logo = er.target.result
          }
          // eslint-disable-next-line prefer-destructuring
          this.logo = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    handleFileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      this.previewVideo()
    },

    previewVideo() {
      const video = document.getElementById('video-preview')
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.addEventListener('load', () => {
        video.src = reader.result
      })
    },
    selectImage() {
      this.$refs.refInputImg.$el.click()
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        loading(true)
        this.search(loading, search)
      }
    },
    async searchGifts(searchQuery) {
      await axios.get(`admin/gifts?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.gifts = res.data?.data.products
        }
      })
    },
    async searchFirstLine(searchQuery) {
      await axios.get(`admin/tags?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.firstLine = res.data?.data.tags
        }
      })
    },
    async getFirstLine() {
      await axios.get('admin/tags').then(res => {
        if (res.status === 200) {
          this.firstLine = res.data?.data.tags
        }
      })
    },
    async getTypes() {
      await axios.get('admin/campaigns').then(res => {
        if (res.status === 200) {
          this.campaignTypes = res.data?.data.campaigns
        }
      })
    },
    async searchCountries(searchQuery) {
      await axios.get(`admin/countries?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async getCountries() {
      await axios.get('admin/countries').then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async searchProducts(searchQuery) {
      await axios.get(`admin/products?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.products = res.data?.data.products
        }
      })
    },
    async getProducts() {
      await axios.get('admin/products?active=1').then(res => {
        if (res.status === 200) {
          this.products = res.data?.data.products
        }
      })
    },
    async getGifts() {
      await axios.get('admin/gifts?status=1').then(res => {
        if (res.status === 200) {
          this.gifts = res.data?.data.products
        }
      })
    },
    showCampaign() {
      if (this.$route.params.id) {
        axios.get(`admin/product_gifts/${this.$route.params.id}`).then(res => {
          this.campaignsForm = res.data.data.product_gift
          const video = document.getElementById('video-preview')

          video.src = this.campaignsForm.video.name
        })
      } else {
        return false
      }
      return true
    },

    addTags() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.tagsForm) {
          formData.append(key, this.tagsForm[key])
        }

        axios.post('admin/tags', formData).then(res => {
          if (res.status === 200) {
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.tagsForm) {
          formData.append(key, this.tagsForm[key])
        }
        this.loader = true
        axios.post('admin/tags', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.getFirstLine()
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

    addCampaign() {
      if (this.$route.params.id) {
        this.loader = true
        this.setFormData(this.campaignsForm)
        this.formData.delete('image')
        this.formData.delete('video')
        this.formData.delete('logo')
        this.formData.delete('gift_name')
        this.formData.delete('product_name')
        this.formData.delete('winner')
        this.formData.delete('gifts')
        this.formData.delete('gift_names[]')
        this.formData.delete('remaining_gifts[]')

        if (this.image) {
          this.formData.append('image', this.image)
        }
        if (this.logo) {
          this.formData.append('logo', this.logo)
        }
        if (this.file) {
          this.formData.append('video', this.file)
        }
        this.formData.append('country_id', this.currentCountry.id)

        axios.post(`admin/product_gifts/${this.$route.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.errorMsg = ''
            this.$router.push({ name: 'watch-campaigns' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true

        this.setFormData(this.campaignsForm)

        this.formData.delete('video')
        this.formData.delete('gift_names')
        this.formData.delete('gifts')
        this.formData.delete('image')
        this.formData.delete('logo')
        if (this.image) {
          this.formData.append('image', this.image)
        } if (this.logo) {
          this.formData.append('logo', this.logo)
        }
        if (this.file) {
          this.formData.append('video', this.file)
        }
        this.formData.append('country_id', this.currentCountry.id)

        axios.post('admin/product_gifts', this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.errorMsg = ''
            this.$router.push({ name: 'watch-campaigns' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
          Object.assign(this.validationErrors, error.response.data.errors)
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.order-type label.btn.btn-primary.active {
    background-color: #0074c7 !important;
}
.addNew{
  color: #f48a36 !important;
  font-size: 10px;
  margin: 0px 5px;
}
.vdp-input-group label {

    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.vdp-input-group input:not(.is-editable) {
    cursor: pointer;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;

}
label{
  font-size:1rem
}
</style>
